.GeneralSetting {
  .header-container {
    font-size: 20px;
    border-bottom: 1px solid #d9dde1;
    padding-bottom: 24px;
  }
}
#gridContainer {
  .dx-datagrid .dx-link {
    color: #344450;
  }
}
.SubHeaderTxt {
  color: #142129;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  padding: 8px 0px;
}

.title-header-text {
  color: #142129;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
