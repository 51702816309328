.main-form-container {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  background-color: white;
  .backbtn {
    margin-bottom: 12px;
  }
  .form-conatiner {
    align-items: start !important;
    padding: 0;
  }
}

@media only screen and (max-width: 343px) {
  .login-container-left {
    width: 100% !important;
  }
  .otp-input-box {
    height: 38px;
    width: 38px;
  }
}
