@mixin sm {
  @media (min-width: 344px) and (max-width: 660px) {
    @content;
  }
}
@mixin md {
  @media (min-width: 660px) and (max-width: 1023px) {
    @content;
  }
}
@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin xsm {
  @media (min-width: 300px) and (max-width: 343px) {
    @content;
  }
}
