@import "../../responsive.scss";

.log-report-input {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;

  .form-input {
    // flex: 1;

    .dx-texteditor-input-container {
      width: 100%;
      height: 56px;
    }
  }
  @include sm {
    flex-direction: column;
    gap: 16px;
    .form-input {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
}

.log-report {
  .dx-toolbar .dx-toolbar-after {
    padding-inline-start: 15px;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
  }
}

.form-input {
  .dx-button {
    background-color: transparent;
  }
}
.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
  border-radius: 4px !important;
}
