@import "../../responsive.scss";
.captureBtn {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 8%;
}
.imgcapture img {
  height: 350px;
  width: 400px;
  margin-top: 30px;
}
@include sm {
  .imgcapture img {
    height: 300px;
    width: 250px;
  }
}
.photo-button1 {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 5rem;
  // top: 60%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: absolute;
  top: 15%;
  left: 15%;
  bottom: 15%;
  right: 15%;
  border-radius: 100%;
  background-color: white;
  opacity: 0;
}

.ring {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  border: 0.2em solid white;
  opacity: 0.8;
}

.photo-button1 .circle,
.photo-button1 .ring {
  transition: all 0.25s;
}

.photo-button1:hover .circle {
  opacity: 1;
}

.photo-button1:active .ring {
  opacity: 1;
}

.photo-button1:active .circle {
  opacity: 0.5;
}
