.on-hover-data {
  margin-top: 16px;
  .dx-datagrid .dx-link {
    color: #344450;
  }
}
.on-hover-data {
  .dx-row.dx-data-row {
    .dx-link.dx-link-edit.dx-icon-edit.dx-link-icon {
      display: none;
      transition: 0.2s;
    }
    .dx-link.dx-link-delete.dx-icon-trash.dx-link-icon {
      display: none;
      transition: 0.2s;
    }
    &:hover {
      .dx-link.dx-link-edit.dx-icon-edit.dx-link-icon {
        display: inline-block;
        opacity: 1;
      }
      .dx-link.dx-link-delete.dx-icon-trash.dx-link-icon {
        display: inline-block;
        opacity: 1;
      }
    }
  }
}
