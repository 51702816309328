.side-nav-inner-toolbar {
  width: 100%;
}

#navigation-header {
  @import "../../themes/generated/variables.additional.scss";
  // background-color: $base-accent;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 10px;
  margin-top: 10px;

  .menu-button .dx-icon {
    color: $base-text-color;
  }

  .screen-x-small & {
    padding-left: 20px;
  }

  .dx-theme-generic & {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-title img {
    margin-left: 10px;
  }
}
