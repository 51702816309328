@import "../../responsive.scss";

.main-report-form {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .form-input {
    flex: 1 1 265px;
    max-width: 265px;

    .dx-texteditor-input-container {
      width: 100%;
      height: 56px;
    }
  }
  @include sm {
    gap: 24px;
    .form-input {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
}
.image img {
  height: 50px;
  width: 50px;
  border-radius: 4px;
}

.initials {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.log-data-grid {
  @include sm {
    .dx-toolbar-after {
      display: flex;
      flex-wrap: wrap;
    }
    .dx-toolbar .dx-toolbar-after {
      margin-top: 50px;
      padding: 0 !important;
    }
    .dx-toolbar {
      height: 200px;
      max-width: 100%;
      // height: 250px;
      // max-width: 100%;
    }
    .dx-toolbar-after {
      .dx-item-content.dx-toolbar-item-content {
        padding-top: 10px;
        // width: 250px !important;
      }
    }
    .dx-datagrid-search-panel {
      margin: 0 0 0 5px;
    }
  }
  @include md {
    .dx-toolbar-after .dx-toolbar-item:last-child {
      padding-inline-start: 0px;
    }
    .dx-toolbar-after {
      display: flex;
      flex-wrap: wrap;
    }
    .dx-toolbar .dx-toolbar-after {
      margin-top: 50px;
      padding: 0 !important;
    }
    .dx-toolbar {
      height: 200px;
      max-width: 100%;
      // height: 250px;
      // max-width: 100%;
    }
    .dx-toolbar-after {
      .dx-item-content.dx-toolbar-item-content {
        padding-top: 10px;
        // width: 250px !important;
      }
    }
    .dx-datagrid-search-panel {
      margin: 0 0 0 5px;
    }
  }
}
