@import "../../responsive.scss";

.profile {
  font-family: inter;

  // .content-block.dx-card {
  //   padding: 0;
  //   padding-top: 24px;
  //   min-height: 70vh;
  // }
  .upper-section {

    // margin-left: 16px;
    // margin-right: 16px;
    // padding-bottom: 24px;
    // border-bottom: 1px solid #d9dde1;
    .form-avatar {
      float: left;
      // height: 104px;
      margin-right: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #fff;
      overflow: hidden;
      display: flex;
      align-items: center;

      img {
        height: 78px;
        display: block;
        margin: 0 auto;
      }
    }

    .about-profile {
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .portal-name {
        font-size: 14px;
      }

      .name-address {
        display: flex;
        gap: 5px;

        // align-items: center;
        .bname {
          font-size: 20px;
          font-weight: 600;
          color: #344450;
          white-space: pre;

          @include sm {
            font-size: 16px;
          }
        }

        :nth-child(2) {
          font-size: 20px;
          color: #344450;
        }

        .cityTxt {
          font-size: 16px;
          font-weight: 400;
          color: #344450;

          @include sm {
            font-size: 14px;
          }
        }
      }

      @include md {
        .name-address {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: start;

          // width: 80%;
          span:nth-of-type(2) {
            display: none;
          }
        }
      }

      .forget-pass {
        color: #6941c6;
        font-weight: 500;

        .dx-button-content {
          justify-content: start;
          padding-inline-start: 0px;
        }
      }

      .forget-pass:hover {
        // .dx-button-mode-text.dx-state-hover {
        background-color: transparent;
        // }
      }

      .dx-button-mode-text.dx-state-active {
        background-color: transparent;
      }

      .dx-button-mode-text.dx-state-hover {
        background-color: transparent;
      }
    }
  }

  .edit-profile-section {

    // margin-left: 16px;
    // margin-right: 16px;
    // // height: 132px;
    // margin-top: 24px;
    .profile-section-header {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 24px;

      .dx-button {
        background-color: transparent !important;

        .dx-icon {
          font-size: 24px !important;
        }
      }
    }

    .profile-section-editor {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      padding-bottom: 16px;

      @include md {
        flex-wrap: wrap;
      }

      @include sm {
        flex-wrap: wrap;
      }

      .uplaod_btn {
        cursor: pointer;
        width: 304px;
        height: 56px;
        border: 1px dashed #9d9b9b;
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        word-spacing: 2px;
        font-size: 14px;
        border-radius: 4px;
        margin-top: 6px;
      }

      .cmplogo_span {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-top: 2px;
        color: red;
        font-size: 12px;
      }
    }
  }
}

.form-avatar canvas {
  height: 100px !important;
  width: 100px !important;
}

.profileHeader {
  display: flex;
  justify-content: space-between;

  .save-btn-section {
    display: flex;
    gap: 8px;
  }

  @include sm {
    flex-direction: column;

    .save-btn-section {
      margin-top: 15px;
    }
  }
}

.form-avatar {
  position: relative;
  display: inline-block;
}

.download-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.form-avatar:hover .download-overlay {
  opacity: 1;
}

.download-text {
  font-size: 16px;
}